import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App.js";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/co2-en.json"
import ru from "./locales/ru/co2-ru.json"

if (!localStorage.getItem('language'))
  localStorage.setItem('language', 'ru')

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      "en-US": {
        translation: en
      },
      ru: {
        translation: ru
      },
      "ru-RU": {
        translation: ru
      },
    },
    lng: localStorage.getItem('language') || navigator.language || navigator.userLanguage,
    fallbackLng: "ru",
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
