export const Icons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="icons">
      <symbol id="turn" viewBox="0 0 116 116" fill="none">
        <path
          d="M72.5021 21.9895C67.7734 20.0854 62.716 19.1312 57.6186 19.1816C52.5212 19.2319 47.4836 20.2858 42.7934 22.2831C38.1033 24.2803 33.8525 27.1818 30.2837 30.8218C26.7149 34.4618 23.898 38.7692 21.9938 43.4979C18.1482 53.0479 18.2537 63.7344 22.2873 73.2065C26.3209 82.6787 33.9521 90.1605 43.5021 94.0062M43.5021 72.4979V96.6645H19.3355"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.7896 34.6074V34.6566"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.8317 96.3789V96.4281"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.3926 88.7891V88.8382"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.6212 72.9883V73.0374"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.3744 53.1641V53.2132"
          stroke="#167FFB"
          strokeWidth="7.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </symbol>

      <symbol id="arrow-back" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7353 1.39247L1.18935 10.9384C0.60356 11.5242 0.60356 12.4739 1.18935 13.0597L10.7353 22.6057C11.3211 23.1915 12.2708 23.1915 12.8566 22.6057C13.4424 22.0199 13.4424 21.0701 12.8566 20.4843L5.87132 13.4991L21.75 13.4991C22.5784 13.4991 23.25 12.8275 23.25 11.9991V11.9991C23.25 11.1706 22.5784 10.4991 21.75 10.4991L5.87132 10.4991L12.8566 3.51379C13.4424 2.928 13.4424 1.97825 12.8566 1.39247C12.2708 0.806679 11.3211 0.806679 10.7353 1.39247Z"
          fill="#646478"
        />
      </symbol>

      <symbol id="closer" viewBox="0 0 14 14" fill="none">
        <path
          d="M12.5498 1L1.00039 12.5494"
          stroke="#2C3E50"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1.00195L12.5494 12.5514"
          stroke="#2C3E50"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </symbol>

      <symbol id="info" viewBox="0 0 32 32" fill="none">
        <path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="#646478"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 10.666H16.0135"
          stroke="#646478"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6665 16H15.9998V21.3333H17.3332"
          stroke="#646478"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </symbol>

      <symbol id="logo" fill="none" viewBox="0 0 166 91">
        <path
          d="M25.4871 31.2528H33.5465V41.1949H42.1482V31.2528H50.2077V58.4147H42.1482V47.6585H33.5465V58.4147H25.4871V31.2528ZM78.9703 31.2528V58.4147H71.1819V37.6917H66.0801V45.4875C66.0801 48.9907 65.809 53.2833 63.6647 55.701C61.5205 58.39 57.4784 59.2041 52.9188 58.39V51.655C57.2073 52.4692 58.2918 50.8409 58.2918 45.4628V31.2035H78.9703V31.2528ZM85.6988 31.2528H93.7583L98.3179 47.3871L102.878 31.2528H110.937L114.708 58.4147H107.191L105.318 43.8839L101.004 58.4147H95.6314L91.3429 43.8839L89.4697 58.4147H81.9525L85.6988 31.2528ZM117.665 31.2528H125.725V42.2804L133.513 31.2528H142.386L132.182 44.6981L142.657 58.4147H132.996L125.75 47.3871V58.4147H117.69V31.2528H117.665ZM82.9877 2.19141C34.631 2.19141 2.63965 19.6825 2.63965 45.4875C2.63965 71.0458 34.6063 88.7836 82.9877 88.7836C131.615 88.7836 163.336 71.0211 163.336 45.4875C163.336 19.6825 131.64 2.19141 82.9877 2.19141ZM82.9877 84.7624C37.5639 84.7624 7.22392 68.8995 7.22392 45.4875C7.22392 21.8288 37.5886 6.21264 82.9877 6.21264C128.658 6.21264 158.776 21.8042 158.776 45.4875C158.776 68.8995 128.683 84.7624 82.9877 84.7624ZM82.9877 9.98717C40.2751 9.98717 11.7835 24.2465 11.7835 45.4875C11.7835 66.7285 40.2751 80.9878 82.9877 80.9878C125.7 80.9878 154.192 66.7285 154.192 45.4875C154.216 24.2465 125.725 9.98717 82.9877 9.98717ZM82.9877 80.1984C40.7926 80.1984 12.5969 66.2104 12.5969 45.5122C12.5969 24.8139 40.8173 10.826 82.9877 10.826C125.158 10.826 153.378 24.8139 153.378 45.5122C153.403 66.2104 125.183 80.1984 82.9877 80.1984Z"
          fill="#4D5460"
        />
      </symbol>

      <symbol id="logo-eng" fill="none" viewBox="0 0 166 91">
        <path d="M25.6879 58.3553V29.3153H34.5255L44.7457 45.6313V29.3153H53.0308V58.3553H44.1932L33.973 41.7588V58.3553H25.6879ZM58.0034 58.3553V29.3153H66.5686V51.4388H73.1987V58.3553H58.0034ZM76.5112 58.3553L80.9313 29.3153H89.2164L94.4614 46.4624L99.434 29.3153H107.997L112.144 58.3553H103.857L101.919 42.5874L97.224 58.3553H91.7039L87.0063 42.5874L84.7963 58.3553H76.5112ZM115.179 58.3553V29.3153H124.017V40.9303L132.305 29.3153H142.25L130.925 43.421L142.527 58.3553H131.752L124.019 46.4624V58.3553H115.179ZM82.8612 0C33.1481 0 0 17.7029 0 44.2496C0 70.5235 33.1481 88.2238 82.8612 88.2238C132.852 88.2238 166 70.5209 166 44.2496C166 17.7003 132.854 0 82.8612 0ZM82.8612 84.3513C36.1805 84.3513 4.97005 68.3081 4.97005 44.2496C4.97005 19.9106 36.1805 3.87251 82.8612 3.87251C129.82 3.87251 161.033 19.9106 161.033 44.2496C161.033 68.3081 129.822 84.3513 82.8612 84.3513ZM82.8612 7.74246C38.9456 7.74246 9.66511 22.4039 9.66511 44.2496C9.66511 66.1003 38.9456 80.7567 82.8612 80.7567C126.779 80.7567 156.06 66.1003 156.06 44.2496C156.06 22.4039 126.779 7.74246 82.8612 7.74246ZM82.8612 79.9256C39.4981 79.9256 10.4951 65.5471 10.4951 44.2496C10.4951 22.9546 39.4981 8.57101 82.8612 8.57101C126.229 8.57101 155.232 22.9521 155.232 44.2496C155.232 65.5471 126.229 79.9256 82.8612 79.9256Z" fill="#4D5460"/>
      </symbol>
    </svg>
  );
};
