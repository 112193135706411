import {useTranslation} from "react-i18next";

export const ExpensesModal = ({ closeModalHandler, children }) => {
  const { t } = useTranslation();

  return (
    <div className="modal large">
      <div className="modal__content">
        <div>
          <h2>{t('SPECIFIC_COSTS_OF_THE_MAIN_TYPES_OF_RAW_MATERIALS_AND_FUEL')}</h2>
          {children}
        </div>
        <svg className="modal__closer" onClick={closeModalHandler}>
          <use xlinkHref="#closer" />
        </svg>
      </div>
    </div>
  );
};
