import {useEffect, useState} from "react";

export const LanguageSwitch = ({ isInnerPage = false }) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('')
  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language') || 'ru');
  }, [])

  const changeLanguage = (language) => {
    const prevLanguage = selectedLanguage;
    if (selectedLanguage === language)
      return;
    localStorage.setItem('language', language)
    setSelectedLanguage(localStorage.getItem('language'));
    window.location.replace(window.location.href.replace(prevLanguage, language));
  }

  return (
    <div className={isInnerPage ? "language language_inner_page" : "language"} onClick={() => { setIsDropdownShown(!isDropdownShown) }}>
      <div className={selectedLanguage === "ru" ? "language-dropdown-option language-dropdown-option-active" : "language-dropdown-option"} onClick={() => { changeLanguage('ru') }}>Ru</div>
      <div className={selectedLanguage === "en" ? "language-dropdown-option language-dropdown-option-active" : "language-dropdown-option"} onClick={() => { changeLanguage('en') }}>En</div>
    </div>
  );
};
