export const prepareExpenses = (t) => {
  const units = {
    kph: `${t('KWT_H')} / ${t('T_STEEL_UNIT')}`,
    kgsteel: `${t('KG')} / ${t('T_STEEL_UNIT')}`,
    kgcast: `${t('KG')} / ${t('T_IRON')}`,
    m3: `${t('M')}³ / ${t('T_STEEL_UNIT')}`,
    pvzh: `${t('KG')} / т ПВЖ`,
  };

  const expense01 = (value) => ({
    image: `/expenses/expense-01.png`,
    title: t('ELECTRICITY'),
    value,
    unit: units.kph,
  });

  const expense02 = (value) => ({
    image: `/expenses/expense-02.png`,
    title: t('PELLETS'),
    value,
    unit: units.pvzh,
  });

  const expense05 = (value) => ({
    image: `/expenses/expense-05.png`,
    title: t('NATURAL_GAS'),
    value,
    unit: units.m3,
  });

  const expense07 = (value) => ({
    image: `/expenses/expense-07.png`,
    title: t('GREEN_ENECTRICITY'),
    value,
    unit: units.kph,
  });

  const expense08 = (value) => ({
    image: `/expenses/expense-08.png`,
    title: t('GREEN_HYDROGEN'),
    value,
    unit: units.m3,
  });

  const expense11 = (value) => ({
    image: `/expenses/expense-11.png`,
    title: t('СARBONACEOUS_MATERIAL'),
    value,
    unit: units.kgsteel,
  });

  return {
    default: [expense02(1400), expense05(330), expense11(20), expense01(700)],
    firstCheck: [expense02(1400), expense08(800), expense11(20), expense01(5100)],
    secondCheck: [expense02(1400), expense05(330), expense11(20), expense07(700)],
    allChecked: [expense02(1400), expense08(800), expense11(20), expense07(700)],
  }
}
