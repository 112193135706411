import "./main-page.css";
import { Link } from "react-router-dom";
import { Logo } from "./logo";
import {LanguageSwitch} from "./language-switch";
import {useTranslation} from "react-i18next";

export const MainPage = () => {
  const language = localStorage.getItem('language')
  const { t } = useTranslation();
  return (
    <div className="main-page">
      <div className="main-page__head">
        <Logo isMain />
        <LanguageSwitch/>

        <span className="main-page__title">
          {t('VARIOUS_STEEL_PRODUCTION_CHAINS_CARBON_INTENSITY')}
        </span>
      </div>

      <div className="main-page__main">
        <div className="main-page__block">
          <div className="main-page__card">
            <div className="main-page__block-img">
              <img src="/slide-1/1.png" alt="#" style={{ 'objectFit': 'contain' }}/>
            </div>

            <span className="main-page__card-text">
              {t('IRON_PRODUCTION_IN_A_BLAST_FURNACE_AND_STEEL_IN_A_CONVERTER')}
            </span>
            <span className="main-page__card-numbers">
              1,7 – 2,1
              <span className="fraction">
                <span>
                  {t('T_CO_UNIT')}<sub>2</sub>
                </span>
                <div className="fraction__line" />
                <span>{t('T_STEEL_UNIT')}</span>
              </span>
            </span>
            <button className="main-page__card-button">
              <Link to={`/${language}/prod-1/`}>{t('OPEN')}</Link>
            </button>
          </div>
        </div>

        <div className="main-page__block">
          <div className="main-page__card">
            <div className="main-page__block-img">
              <img src="/slide-1/2.png" alt="#" style={{ 'objectFit': 'contain' }}/>
            </div>
            <span className="main-page__card-text">
              {t('PRODUCTION_OF_STEEL_IN_AN_ELECTRIC_ARC_FURNACE_FROM_SCRAP')}
            </span>
            <span className="main-page__card-numbers">
              0,1 – 0,4
              <span className="fraction">
                <span>
                  {t('T_CO_UNIT')}<sub>2</sub>
                </span>
                <div className="fraction__line" />
                <span>{t('T_STEEL_UNIT')}</span>
              </span>
            </span>
            <button className="main-page__card-button">
              <Link to={`/${language}/prod-2/`}>{t('OPEN')}</Link>
            </button>
          </div>
        </div>

        <div className="main-page__block">
          <div className="main-page__card">
            <div className="main-page__block-img">
              <img src="/slide-1/3.png" alt="#" style={{ 'objectFit': 'contain' }} />
            </div>
            <span className="main-page__card-text">
              {t('STEEL_PRODUCTION_IN_AN_ELECTRIC_ARC_FURNACE_FROM_DIRECT_REDUCED_IRON_DRI')}
            </span>
            <span className="main-page__card-numbers">
              0,2 – 1,2
              <span className="fraction">
                <span>
                  {t('T_CO_UNIT')}<sub>2</sub>
                </span>
                <div className="fraction__line" />
                <span>{t('T_STEEL_UNIT')}</span>
              </span>
            </span>
            <button className="main-page__card-button">
              <Link to={`/${language}/prod-3/`}>{t('OPEN')}</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
