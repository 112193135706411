import {useTranslation} from "react-i18next";

export const AsideLink = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="aside__link" onClick={onClick}>
      <span>{t('SPECIFIC_COSTS_OF_THE_MAIN_TYPES_OF_RAW_MATERIALS_AND_FUEL')}</span>
      <img src="/question.svg" alt="#" width="24px" height="24px" />
    </div>
  );
};
