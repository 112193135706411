import {useTranslation} from "react-i18next";

export const TurnPhone = () => {
  const { t } = useTranslation();

  return (
    <div className="turn-phone">
      <svg>
        <use xlinkHref="#turn" />
      </svg>
      <span>
        {t('ROTATE_PHONE')}
      </span>
    </div>
  );
};
