export const prepareExpenses = (t) => {
  const units = {
    kph: `${t('KWT_H')} / ${t('T_STEEL_UNIT')}`,
    kgsteel: `${t('KG')} / ${t('T_STEEL_UNIT')}`,
    kgcast: `${t('KG')} / ${t('T_IRON')}`,
    m3: `${t('M')}³ / ${t('T_STEEL_UNIT')}`,
  };

  const expense01 = (value) => ({
    image: `/expenses/expense-01.png`,
    title: t('ELECTRICITY'),
    value,
    unit: units.kph,
  });

  const expense10 = (value) => ({
    image: `/expenses/expense-10.png`,
    title: t('SCRAP'),
    value,
    unit: units.kgsteel,
  });

  const expense11 = (value) => ({
    image: `/expenses/expense-11.png`,
    title: t('СARBONACEOUS_MATERIAL'),
    value,
    unit: units.kgsteel,
  });

  const expense07 = (value) => ({
    image: `/expenses/expense-07.png`,
    title: t('GREEN_ENECTRICITY'),
    value,
    unit: units.kph,
  });

  return {
    default: [expense10(1100), expense11(20), expense01(500)],
    lowCarbon: [expense10(1100), expense11(20), expense07(500)],
  }
}
