import { AsideLink } from "../aside-link";
import {useTranslation} from "react-i18next";

export const Footer = ({
  emissionSum,
  persents,
  colorOfPercents,
  text,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="aside__foot">
        <span className="h3">{t('TOTAL_EMISSION')}</span>
        <div style={{ marginBottom: `12px` }}>
          <span className="h2" style={{ marginRight: `16px` }}>
            {emissionSum}
          </span>
          <span className="extra">
            {t('T_CO_UNIT')}<sub>2</sub> / {t('T_STEEL_UNIT')}
          </span>
        </div>
        <span className="h3" style={{ marginBottom: `4px` }}>
          {t('EMISSION_REDUCTION_RELATIVE_TO_THE_BASE_CHAIN')}
        </span>
        <span className="green" style={{ color: `#25B06D` }}>
          {persents}
        </span>
      </div>
      <AsideLink onClick={onClick} />
    </>
  );
};
