export const prepareExpenses = (t) => {
  const units = {
    kph: `${t('KWT_H')} / ${t('T_STEEL_UNIT')}`,
    kgsteel: `${t('KG')} / ${t('T_STEEL_UNIT')}`,
    kgcast: `${t('KG')} / ${t('T_IRON')}`,
    m3: `${t('M')}³ / ${t('T_STEEL_UNIT')}`,
  };

  const expense01 = (value) => ({
    image: `/expenses/expense-01.png`,
    title: t('ELECTRICITY'),
    value,
    unit: units.kph,
  });

  const expense02 = (value) => ({
    image: `/expenses/expense-02.png`,
    title: t('PELLETS'),
    value,
    unit: units.kgcast,
  });

  const expense03 = (value) => ({
    image: `/expenses/expense-03.png`,
    title: t('COKE'),
    value,
    unit: units.kgcast,
  });

  const expense04 = (value) => ({
    image: `/expenses/expense-04.png`,
    title: t('AGGLOMERATE'),
    value,
    unit: units.kgcast,
  });

  const expense05 = (value) => ({
    image: `/expenses/expense-05.png`,
    title: t('NATURAL_GAS'),
    value,
    unit: units.m3,
  });

  const expense06 = (value) => ({
    image: `/expenses/expense-06.png`,
    title: t('PULVERIZED_COAL_FUEL'),
    value,
    unit: units.kgcast,
  });

  const expense07 = (value) => ({
    image: `/expenses/expense-07.png`,
    title: t('GREEN_ENECTRICITY'),
    value,
    unit: units.kph,
  });

  const expense08 = (value) => ({
    image: `/expenses/expense-08.png`,
    title: t('GREEN_HYDROGEN'),
    value,
    unit: units.m3,
  });

  const expense09 = (value) => ({
    image: `/expenses/expense-09.png`,
    title: t('BIOCHAR'),
    value,
    unit: units.kgsteel,
  });

  return {
    default: [
      expense02(600),
      expense04(1030),
      expense03(380),
      expense05(150),
      expense06(130),
      expense01(430),
    ],
    lowCarbon: [
      expense02(600),
      expense04(1030),
      expense03(380),
      expense05(150),
      expense06(110),
      expense07(1500),
      expense09(27),
      expense08(200),
    ],
  }
}
