import { useState } from "react";
import { Link } from "react-router-dom";
import { TurnPhone } from "../turn-phone";
import { Modal } from "../modal";
import { Logo } from "../logo";
import { prepareExpenses } from "./expenses";
import { ExpensesModal } from "../expenses";
import { ExpenseItem } from "../expense-item";
import { AsideLink } from "../aside-link";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../language-switch";

export const Prod2 = () => {
  const { t } = useTranslation();
  const translation = localStorage.getItem("language");
  const expenses = prepareExpenses(t);

  const [toggleOn, setToggleOn] = useState(false);
  const toggler = () => {
    setTimeout(() => {
      setToggleOn(!toggleOn);
    }, 500);
  };

  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isExpensesOpen, setExpensesOpen] = useState(false);
  const [isMobileAsideOpen, setMobileAsideOpen] = useState(false);

  return (
    <>
      {isInfoOpen && <Modal closeModalHandler={() => setInfoOpen(false)} />}

      {isExpensesOpen && (
        <ExpensesModal closeModalHandler={() => setExpensesOpen(false)}>
          <div className="expense-items">
            {(toggleOn ? expenses.lowCarbon : expenses.default).map(
              (expense) => (
                <ExpenseItem {...expense} />
              )
            )}
          </div>
        </ExpensesModal>
      )}

      <div className="page">
        <TurnPhone />

        <Link to={`/${translation}/`} className="back-btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.7353 1.39247L1.18935 10.9384C0.60356 11.5242 0.60356 12.4739 1.18935 13.0597L10.7353 22.6057C11.3211 23.1915 12.2708 23.1915 12.8566 22.6057C13.4424 22.0199 13.4424 21.0701 12.8566 20.4843L5.87132 13.4991L21.75 13.4991C22.5784 13.4991 23.25 12.8275 23.25 11.9991V11.9991C23.25 11.1706 22.5784 10.4991 21.75 10.4991L5.87132 10.4991L12.8566 3.51379C13.4424 2.928 13.4424 1.97825 12.8566 1.39247C12.2708 0.806679 11.3211 0.806679 10.7353 1.39247Z"
              fill="#646478"
            />
          </svg>
          <span>{t("HOMEPAGE")}</span>
        </Link>

        {/* mobile */}
        <div className="modal__trigger" onClick={() => setInfoOpen(true)}>
          <img src="/info.svg" alt="#" width="32px" height="32px" />
        </div>
        <div className="bottom">
          <div className="legend">
            <div className="legend__item m--long">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">
                {t("RAW_MATERIALS_AND_SUPPLIES_FOR_STEEL_PRODUCTION")}
              </div>
            </div>
          </div>
          <div className="info_text">{t("INFO_TEXT")}</div>
        </div>

        <main className="main">
          {/* desktop & tablet */}
          <LanguageSwitch isInnerPage />
          <Logo />

          {/*<div className="loader">*/}
          {/*  <Loader />*/}
          {/*</div>*/}

          {(isDesktop || isTablet || isMobile) && (
            <div className="video__wrapper">
              <video
                className={toggleOn ? "video unactive" : "video active"}
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/02_off.jpg"
                    : "/video/eng/02eng_off.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off.mp4"
                      : "/video/eng/02eng_off.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off.ogg"
                      : "/video/eng/02eng_off.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off.webm"
                      : "/video/eng/02eng_off.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={toggleOn ? "video active" : "video unactive"}
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on.mp4"
                      : "/video/eng/02eng_on.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on.ogg"
                      : "/video/eng/02eng_on.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on.webm"
                      : "/video/eng/02eng_on.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )}
          {/* {isMobile && (
            <div className="video__wrapper">
              <video
                className={toggleOn ? "video unactive" : "video active"}
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/02_off_small.jpg"
                    : "/video/eng/02eng_off_small.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off_small.mp4"
                      : "/video/eng/02eng_off_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off_small.ogg"
                      : "/video/eng/02eng_off_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_off_small.webm"
                      : "/video/eng/02eng_off_small.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={toggleOn ? "video active" : "video unactive"}
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on_small.mp4"
                      : "/video/eng/02eng_on_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on_small.ogg"
                      : "/video/eng/02eng_on_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/02_on_small.webm"
                      : "/video/eng/02eng_on_small.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )} */}
        </main>

        <aside
          className={isMobileAsideOpen ? "aside aside--mobile-open" : "aside"}
        >
          <div className="aside__top">
            <div className="aside__head">
              <span
                className="h3"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <b className="aside__head_desktop">
                  {t("LOW_CARBON_RESOURCES")}
                </b>
                <b className="aside__head_mobile">
                  {t("ACTIVATE_LOW_CARBON_RESOURCES")}
                </b>
              </span>
              <svg
                className="aside__mobile-closer"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <use xlinkHref="#closer" />
              </svg>
            </div>

            <div className="aside__main">
              <div
                className={`aside__main__row ${
                  !toggleOn ? "aside__main__row_disabled" : ""
                }`}
                style={{ marginTop: `16px` }}
              >
                <img src="/3.png" alt="#" width="64px" height="64px" />

                <span className="text">{t("GREEN_ENECTRICITY")}</span>

                <label style={{ margin: `0 24px 0 auto` }}>
                  <input type="checkbox" id="toggler" onChange={toggler} />
                  <label htmlFor="toggler" className="toggler__wrap">
                    <label htmlFor="toggler" className="toggler" />
                  </label>
                </label>
              </div>
            </div>
          </div>

          {toggleOn ? (
            <div className="aside__foot">
              <span className="h3">{t("TOTAL_EMISSION")}</span>
              <div style={{ marginBottom: `12px` }}>
                <span className="h2" style={{ marginRight: `16px` }}>
                  0,1
                </span>
                <span className="extra">
                  {t("T_CO_UNIT")}
                  <sub>2</sub> / {t("T_STEEL_UNIT")}
                </span>
              </div>
              <p className="h3" style={{ marginBottom: `4px` }}>
                {t("EMISSION_REDUCTION_RELATIVE_TO_THE_BASE_CHAIN")}
              </p>
              <span className="green">−95%</span>
            </div>
          ) : (
            <div className="aside__foot">
              <span className="h3">{t("TOTAL_EMISSION")}</span>
              <div style={{ marginBottom: `12px` }}>
                <span className="h2" style={{ marginRight: `16px` }}>
                  0,4
                </span>
                <span className="extra">
                  {t("T_CO_UNIT")}
                  <sub>2</sub> / {t("T_STEEL_UNIT")}
                </span>
              </div>
              <p className="h3" style={{ marginBottom: `4px` }}>
                {t("EMISSION_REDUCTION_RELATIVE_TO_THE_BASE_CHAIN")}
              </p>
              <span className="green">−80%</span>
            </div>
          )}
          <AsideLink onClick={() => setExpensesOpen(true)} />
        </aside>
      </div>
    </>
  );
};
