import { MainPage } from "./slides/main-page";
import { Prod1 } from "./slides/prod-1/prod-1";
import { Prod2 } from "./slides/prod-2/prod-2";
import { Prod3 } from "./slides/prod-3/prod-3";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Icons } from "./slides/icons";

export const App = () => {
  const language = localStorage.getItem('language')

  if (window.location.pathname === '/')
    window.location.replace(`/${language}/`)

  function redirect(nextState, replace) {
    replace({
      pathname: `/${language}/`,
      state: { nextPathname: nextState.location.pathname }
    })
  }

  return (
    <>
      <Icons />
      <Router>
        <Routes>
          <Route path='/' onEnter={redirect} element={<MainPage />} />
          <Route path={`/${language}/`} exact element={<MainPage />} />
          <Route path={`/${language}/prod-1`} element={<Prod1 />} />
          <Route path={`/${language}/prod-2`} element={<Prod2 />} />
          <Route path={`/${language}/prod-3`} element={<Prod3 />} />
        </Routes>
      </Router>
    </>
  );
};
