export const ExpenseItem = ({ title = "", image, value, unit }) => (
  <div className="expense-item">
    <img className="expense-item__image" src={image} alt={title} />
    <div className="expense-item__content">
      <div className="expense-item__title">{title}</div>
      <div className="expense-item__subtitle">
        <span className="expense-item__value">{value}</span>
        <span className="expense-item__unit">{unit}</span>
      </div>
    </div>
  </div>
);
