import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TurnPhone } from "../turn-phone";
import { Footer } from "./footer";
import { Modal } from "../modal";
import { Logo } from "../logo";
import { prepareExpenses } from "./expenses";
import { ExpensesModal } from "../expenses";
import { ExpenseItem } from "../expense-item";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../language-switch";

export const Prod3 = () => {
  const { t } = useTranslation();
  const translation = localStorage.getItem("language");
  const expenses = prepareExpenses(t);
  const [isFirstTogglerOn, setIsFirstTogglerOn] = useState(false);
  const [isSecondTogglerOn, setIsSecondTogglerOn] = useState(false);

  const [emissionSum, setEmissionSum] = useState("");
  const [percentsData, setPercentsData] = useState("");

  const toggleFirstToggler = () => {
    setTimeout(() => {
      setIsFirstTogglerOn(!isFirstTogglerOn);
    }, 500);
  };

  const toggleSecondToggler = () => {
    setTimeout(() => {
      setIsSecondTogglerOn(!isSecondTogglerOn);
    }, 500);
  };

  const [chekedSituation, setCheckedSituation] = useState("default");

  useEffect(() => {
    let foo;
    let boo;
    if (!isFirstTogglerOn && !isSecondTogglerOn) {
      foo = "1,2";
      boo = "−43%";
      setCheckedSituation("default");
    } else if (isFirstTogglerOn && !isSecondTogglerOn) {
      foo = "0,6";
      boo = "−72%";
      setCheckedSituation("firstChecked");
    } else if (!isFirstTogglerOn && isSecondTogglerOn) {
      foo = "0,8";
      boo = "−63%";
      setCheckedSituation("secondChecked");
    } else if (isFirstTogglerOn && isSecondTogglerOn) {
      foo = "0,2";
      boo = "−90%";
      setCheckedSituation("allChecked");
    }

    setEmissionSum(foo);
    setPercentsData(boo);
  }, [isFirstTogglerOn, isSecondTogglerOn]);

  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isExpensesOpen, setExpensesOpen] = useState(false);
  const [isMobileAsideOpen, setMobileAsideOpen] = useState(false);

  return (
    <>
      {isInfoOpen && <Modal closeModalHandler={() => setInfoOpen(false)} />}

      {isExpensesOpen && (
        <ExpensesModal closeModalHandler={() => setExpensesOpen(false)}>
          <div className="expense-items">
            {(chekedSituation === "default"
              ? expenses.default
              : chekedSituation === "firstChecked"
              ? expenses.firstCheck
              : chekedSituation === "secondChecked"
              ? expenses.secondCheck
              : expenses.allChecked
            ).map((expense) => (
              <ExpenseItem {...expense} />
            ))}
          </div>
        </ExpensesModal>
      )}

      <div className="page">
        <TurnPhone />
        <Link to={`/${translation}/`} className="back-btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.7353 1.39247L1.18935 10.9384C0.60356 11.5242 0.60356 12.4739 1.18935 13.0597L10.7353 22.6057C11.3211 23.1915 12.2708 23.1915 12.8566 22.6057C13.4424 22.0199 13.4424 21.0701 12.8566 20.4843L5.87132 13.4991L21.75 13.4991C22.5784 13.4991 23.25 12.8275 23.25 11.9991V11.9991C23.25 11.1706 22.5784 10.4991 21.75 10.4991L5.87132 10.4991L12.8566 3.51379C13.4424 2.928 13.4424 1.97825 12.8566 1.39247C12.2708 0.806679 11.3211 0.806679 10.7353 1.39247Z"
              fill="#646478"
            />
          </svg>
          <span>{t("HOMEPAGE")}</span>
        </Link>

        {/* mobile */}
        <div className="modal__trigger" onClick={() => setInfoOpen(true)}>
          <img src="/info.svg" alt="#" width="32px" height="32px" />
        </div>
        <div className="bottom">
          <div className="legend">
            <div className="legend__item">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">{t("FUEL")}</div>
            </div>
            <div className="legend__item m--long">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">{t("DRI_AND_STEEL")}</div>
            </div>
          </div>
          <div className="info_text">{t("INFO_TEXT")}</div>
        </div>

        <main className="main">
          {/* desktop & tablet */}
          <LanguageSwitch isInnerPage />
          <Logo />

          {/*<div className="loader">*/}
          {/*  <Loader />*/}
          {/*</div>*/}

          {(isDesktop || isTablet || isMobile) && (
            <div className="video__wrapper">
              <video
                className={
                  !isFirstTogglerOn && !isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/03_off.jpg"
                    : "/video/eng/03eng_off.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off.mp4"
                      : "/video/eng/03eng_off.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off.ogg"
                      : "/video/eng/03eng_off.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off.webm"
                      : "/video/eng/03eng_off.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  isFirstTogglerOn && !isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2.mp4"
                      : "/video/eng/03eng_on2.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2.ogg"
                      : "/video/eng/03eng_on2.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2.webm"
                      : "/video/eng/03eng_on2.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  !isFirstTogglerOn && isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1.mp4"
                      : "/video/eng/03eng_on1.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1.ogg"
                      : "/video/eng/03eng_on1.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1.webm"
                      : "/video/eng/03eng_on1.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  isFirstTogglerOn && isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3.mp4"
                      : "/video/eng/03eng_on3.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3.ogg"
                      : "/video/eng/03eng_on3.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3.webm"
                      : "/video/eng/03eng_on3.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )}
          {/* {isMobile && (
            <div className="video__wrapper">
              <video
                className={
                  !isFirstTogglerOn && !isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/03_off_small.jpg"
                    : "/video/eng/03eng_off_small.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off_small.mp4"
                      : "/video/eng/03eng_off_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off_small.ogg"
                      : "/video/eng/03eng_off_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_off_small.webm"
                      : "/video/eng/03eng_off_small.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  isFirstTogglerOn && !isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2_small.mp4"
                      : "/video/eng/03eng_on2_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2_small.ogg"
                      : "/video/eng/03eng_on2_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on2_small.webm"
                      : "/video/eng/03eng_on2_small.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  !isFirstTogglerOn && isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1_small.mp4"
                      : "/video/eng/03eng_on1_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1_small.ogg"
                      : "/video/eng/03eng_on1_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on1_small.webm"
                      : "/video/eng/03eng_on1_small.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={
                  isFirstTogglerOn && isSecondTogglerOn
                    ? "video active"
                    : "video unactive"
                }
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3_small.mp4"
                      : "/video/eng/03eng_on3_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3_small.ogg"
                      : "/video/eng/03eng_on3_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/03_on3_small.webm"
                      : "/video/eng/03eng_on3_small.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )} */}
        </main>

        <aside
          className={isMobileAsideOpen ? "aside aside--mobile-open" : "aside"}
        >
          <div className="aside__top">
            <div className="aside__head">
              <span
                className="h3"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <b className="aside__head_desktop">
                  {t("LOW_CARBON_RESOURCES")}
                </b>
                <b className="aside__head_mobile">
                  {t("ACTIVATE_LOW_CARBON_RESOURCES")}
                </b>
              </span>
              <svg
                className="aside__mobile-closer"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <use xlinkHref="#closer" />
              </svg>
            </div>

            <div className="aside__main">
              <div
                className={`aside__main__row ${
                  !isFirstTogglerOn ? "aside__main__row_disabled" : ""
                }`}
                style={{ marginTop: `16px` }}
              >
                <img src="/2.png" alt="#" width="64px" height="64px" />

                <span className="text">{t("GREEN_HYDROGEN")}</span>

                <label>
                  <input
                    type="checkbox"
                    id="firstToggler"
                    onChange={toggleFirstToggler}
                  />
                  <label htmlFor="firstToggler" className="toggler__wrap">
                    <label htmlFor="firstToggler" className="toggler" />
                  </label>
                </label>
              </div>

              <div
                className={`aside__main__row ${
                  !isSecondTogglerOn ? "aside__main__row_disabled" : ""
                }`}
                style={{ marginTop: `4px` }}
              >
                <img src="/3.png" alt="#" width="64px" height="64px" />

                <span className="text">{t("GREEN_ENECTRICITY")}</span>

                <label>
                  <input
                    type="checkbox"
                    id="secondToggler"
                    onChange={toggleSecondToggler}
                  />
                  <label htmlFor="secondToggler" className="toggler__wrap">
                    <label htmlFor="secondToggler" className="toggler" />
                  </label>
                </label>
              </div>
            </div>
          </div>

          <Footer
            emissionSum={emissionSum}
            persents={percentsData}
            onClick={() => setExpensesOpen(true)}
          />
        </aside>
      </div>
    </>
  );
};
