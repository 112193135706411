export const Logo = ({ isMain }) => {
  const translation = localStorage.getItem('language')
  return (
    <a href="https://nlmk.com/ru/" target="_blank" rel="noreferrer">
      <svg className={isMain ? "logo logo--main" : "logo"}>
        <use xlinkHref={translation === "ru" ? "#logo" : "#logo-eng"} />
      </svg>
    </a>
  );
};
