import {useTranslation} from "react-i18next";

export const Modal = ({ closeModalHandler }) => {
  const { t } = useTranslation();

  return (
    <div className="modal">
      <div className="modal__content">
        <span>
          {t('INFO_TEXT')}
        </span>
        <svg className="modal__closer" onClick={closeModalHandler}>
          <use xlinkHref="#closer" />
        </svg>
      </div>
    </div>
  );
};
