import "./prod-1.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { TurnPhone } from "../turn-phone";
import { Modal } from "../modal";
import { ExpensesModal } from "../expenses";
import { Logo } from "../logo";
import { prepareExpenses } from "./expenses";
import { ExpenseItem } from "../expense-item";
import { AsideLink } from "../aside-link";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../language-switch";

export const Prod1 = () => {
  const { t } = useTranslation();
  const translation = localStorage.getItem("language");
  const expenses = prepareExpenses(t);

  const [toggleOn, setToggleOn] = useState(false);
  const toggler = () => {
    setTimeout(() => {
      setToggleOn(!toggleOn);
    }, 500);
  };

  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isExpensesOpen, setExpensesOpen] = useState(false);
  const [isMobileAsideOpen, setMobileAsideOpen] = useState(false);

  return (
    <>
      {isInfoOpen && <Modal closeModalHandler={() => setInfoOpen(false)} />}
      {isExpensesOpen && (
        <ExpensesModal closeModalHandler={() => setExpensesOpen(false)}>
          <div className="expense-items">
            {(toggleOn ? expenses.lowCarbon : expenses.default).map(
              (expense) => (
                <ExpenseItem {...expense} />
              )
            )}
          </div>
        </ExpensesModal>
      )}

      <div className="page">
        <TurnPhone />

        <Link to={`/${translation}/`} className="back-btn">
          <svg>
            <use xlinkHref="#arrow-back" />
          </svg>
          <span>{t("HOMEPAGE")}</span>
        </Link>

        {/* mobile */}
        <div className="modal__trigger" onClick={() => setInfoOpen(true)}>
          <img src="/info.svg" alt="#" width="32px" height="32px" />
        </div>
        <div className="bottom">
          <div className="legend">
            <div className="legend__item">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">{t("FUEL")}</div>
            </div>
            <div className="legend__item m--round">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">{t("EXHAUST_GASES")}</div>
            </div>
            <div className="legend__item m--long">
              <div className="legend__item_anim"></div>
              <div className="legend__item_text">
                {t("RAW_MATERIALS_AND_SUPPLIES_FOR_STEEL_PRODUCTION")}
              </div>
            </div>
          </div>
          <div className="info_text">{t("INFO_TEXT")}</div>
        </div>

        <main className="main">
          {/* desktop & tablet */}
          <LanguageSwitch isInnerPage />
          <Logo />

          {/*<div className="loader">*/}
          {/*  <Loader />*/}
          {/*</div>*/}
          {(isDesktop || isTablet || isMobile) && (
            <div className="video__wrapper">
              <video
                className={toggleOn ? "video unactive" : "video active"}
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/01_off.jpg"
                    : "/video/eng/01_off.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off.mp4"
                      : "/video/eng/01eng_off.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off.ogg"
                      : "/video/eng/01eng_off.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off.webm"
                      : "/video/eng/01eng_off.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={toggleOn ? "video active" : "video unactive"}
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on.mp4"
                      : "/video/eng/01eng_on.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on.ogg"
                      : "/video/eng/01eng_on.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on.webm"
                      : "/video/eng/01eng_on.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )}
          {isMobile}
          {/* {isMobile && (
            <div className="video__wrapper">
              <video
                className={toggleOn ? "video unactive" : "video active"}
                autoPlay
                loop
                muted
                playsInline
                poster={
                  translation === "ru"
                    ? "/video/01_off_small.jpg"
                    : "/video/eng/01eng_off_small.jpg"
                }
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off_small.mp4"
                      : "/video/eng/01eng_off_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off_small.ogg"
                      : "/video/eng/01eng_off_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_off_small.webm"
                      : "/video/eng/01eng_off_small.webm"
                  }
                  type="video/webm"
                />
              </video>

              <video
                className={toggleOn ? "video active" : "video unactive"}
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on_small.mp4"
                      : "/video/eng/01eng_on_small.mp4"
                  }
                  type="video/mp4"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on_small.ogg"
                      : "/video/eng/01eng_on_small.ogg"
                  }
                  type="video/ogg"
                />
                <source
                  src={
                    translation === "ru"
                      ? "/video/01_on_small.webm"
                      : "/video/eng/01eng_on_small.webm"
                  }
                  type="video/webm"
                />
              </video>
            </div>
          )} */}
        </main>

        <aside
          className={
            isMobileAsideOpen
              ? "aside aside--mobile-open"
              : "aside aside--first-slide"
          }
        >
          <div className="aside__top">
            <div className="aside__head">
              <span
                className="h3"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <b className="aside__head_desktop">
                  {t("LOW_CARBON_RESOURCES")}
                </b>
                <b className="aside__head_mobile">
                  {t("ACTIVATE_LOW_CARBON_RESOURCES")}
                </b>
              </span>
              <label>
                <input type="checkbox" id="toggler" onChange={toggler} />
                <label htmlFor="toggler" className="toggler__wrap">
                  <label htmlFor="toggler" className="toggler" />
                </label>
              </label>
              <svg
                className="aside__mobile-closer"
                onClick={() => setMobileAsideOpen(!isMobileAsideOpen)}
              >
                <use xlinkHref="#closer" />
              </svg>
            </div>
            <div className={`aside__main ${!toggleOn ? "disabled" : ""}`}>
              <div className="aside__main__row">
                <img src="/1.png" alt="#" width="64px" height="64px" />
                <span className="text">{t("BIOCHAR")}</span>
              </div>
              <div className="aside__main__row">
                <img src="/2.png" alt="#" width="64px" height="64px" />
                <span className="text">{t("GREEN_HYDROGEN")}</span>
              </div>
              <div className="aside__main__row">
                <img src="/3.png" alt="#" width="64px" height="64px" />
                <span className="text">{t("GREEN_ENECTRICITY")}</span>
              </div>
            </div>
          </div>

          <div className="aside__foot">
            <span className="h3">{t("TOTAL_EMISSION")}</span>
            <div style={{ marginBottom: `12px` }}>
              <span className="h2" style={{ marginRight: `16px` }}>
                {toggleOn ? "1,7" : "2,1"}
              </span>
              <span className="extra">
                {t("T_CO_UNIT")}
                <sub>2</sub> / {t("T_STEEL_UNIT")}
              </span>
            </div>
            {toggleOn ? (
              <>
                <p className="h3" style={{ marginBottom: `4px` }}>
                  {t("EMISSION_REDUCTION_RELATIVE_TO_THE_BASE_CHAIN")}
                </p>
                <span className="green">−20%</span>
              </>
            ) : (
              <span className="h3">
                {t("BASIC_CHAIN_OF_IRON_AND_STEEL_PRODUCTION")}
              </span>
            )}
          </div>

          <AsideLink onClick={() => setExpensesOpen(true)} />
        </aside>
      </div>
    </>
  );
};
